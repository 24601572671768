<template>
  <div>
    <div class="mainCont">
      <div class="def-container bannerTxt">
        <span class="chTxt">惠企政策</span><br />
        <span class="enTxt">FAVORABLE ENTERPRISE POLICY</span>
      </div>
    </div>
    <div class="def-container find-list-container">
      <div class="findList" ref="findList">
        <!--筛选-->
        <div class="find-tabs">
          <FilterGroup @change="changeSearch">
            <FilterItem
              v-model="searchInfo.data.level"
              label="行政层级"
              :list="getDictList('policy_level').slice(2).concat(getDictList('policy_level_qx'))"
              valueKey="key"
            ></FilterItem>
            <FilterItem
              v-model="searchInfo.data.deptcode"
              label="发布部门"
              :list="searchInfo.deptList"
              valueKey="deptid"
              labelKey="deptname"
              fold
            ></FilterItem>
            <FilterItem
              v-model="searchInfo.data.cylb"
              label="产业类别"
              :list="cpu_cylbList"
              :needAll="false"
              valueKey="key"
              fold
            ></FilterItem>
            <FilterItem
              v-model="searchInfo.data.zcfs"
              label="支持方式"
              :list="getDictList('sq_policy_zjlx')"
              valueKey="key"
            ></FilterItem>
            <FilterItem
              v-show="searchInfo.data.zcfs == '1'"
              class="searchItem-amount"
              v-model="searchInfo.data.temp_amount"
              label="资金限额"
              :list="searchInfo.amountList"
            >
              <template v-slot:append>
                <div
                  v-show="searchInfo.data.temp_amount == '4'"
                  class="searchItem-amount-custom"
                >
                  <FormInput
                    v-model.number="searchInfo.data.zzxeleft"
                    size="small"
                    @change="changeSearch"
                  ></FormInput
                  >万元
                  <span style="margin: 0 5px">~</span>
                  <FormInput
                    v-model.number="searchInfo.data.zzxeright"
                    size="small"
                    @change="changeSearch"
                  ></FormInput
                  >万元
                </div>
              </template>
            </FilterItem>
            <FilterItem label="已选择" :needAll="false">
              <div class="filter-params-inner">
                <div class="filterItem-params-left">
                  <span v-if="!cpu_paramsList.length">无</span>
                  <a
                    v-else
                    class="pad-rl-1 search-params-clean"
                    @click="cleanParams"
                    >清空</a
                  >
                  <el-tag
                    v-for="(item, index) in cpu_paramsList"
                    :key="index"
                    closable
                    @close="closeParams(item)"
                  >
                    {{ item.label }}
                  </el-tag>
                </div>
                <div class="filterItem-params-right">
                  <el-radio-group
                    v-model="searchInfo.type"
                    size="small"
                    @change="changeSearchType"
                  >
                    <!-- <el-radio-button label="1">数字化列表</el-radio-button> -->
                    <!-- <el-radio-button label="0">传统列表</el-radio-button> -->
                  </el-radio-group>
                </div>
              </div>
            </FilterItem>
          </FilterGroup>
        </div>
      </div>
      <div class="input-search-bar">
        <el-input class="input-search" v-model="keyWords" placeholder="请输入关键字搜索" clearable @clear="clear">
        </el-input>
        <el-button class="input-search-btn" type="primary" @click="toSearch"
          >搜索
        </el-button>
      </div>
      <div class="marg-tb-1 wrap-total" style="font-size: 18px">
        共 <span style="color: #ff8f20">{{ pageInfo.total }}</span> 条政策
      </div>
      <div class="wrap-content">
        <div
          v-if="searchInfo.type == '0'"
          ref="findListContent"
          class="marg-tb-3 find-list-items-container"
          v-loading="dataLoading"
        >
          <div class="item" v-for="(item, i) in policyDataList" :key="i">
            <DeclareCell
              :key="item.id"
              :collectItem="isItemCollect(item)"
              :item="item"
              :isShowText="false"
              @clickCell="clickCell"
              @clickIsFollow="clickIsFollow"
            ></DeclareCell>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="total,prev,pager,next,jumper"
          :current-page="pageInfo.page"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>

      <!--登录提示弹窗-->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>需要登录才能收藏，是否登录？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="gotoLogin">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FormInput } from "@/components/form/index.js";
import DeclareCell from "@/components/declareCellNew"; // 申报项目，智能推荐卡片
import { FilterGroup, FilterItem } from "./components/filter/index.js";

export default {
  name: "PolicyProgram",
  components: {
    FormInput,
    DeclareCell,
    FilterGroup,
    FilterItem,
  },
  data() {
    return {
      keyWords:"",//搜索关键字
      xzcjList: [], //行政层级
      policyDataList: [],
      dialogVisible: false, //登录提示弹窗
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          level: "", //行使层级
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 5,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 产业类别列表
     */
    cpu_cylbList() {
      let list = JSON.parse(JSON.stringify(this.getDictList("sys_enter_type")));
      if (!!list.length) {
        list[0].key = "";
        list[0].label = "不限";
      }

      return list;
    },
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];
      const params = this.searchInfo.oldParams;
      // console.log('oldParams:', params)
      if (params.deptcode) {
        list.push({
          type: "deptcode",
          value: params.deptcode,
          label: this.searchInfo.deptList.find(
            (cv) => cv.deptid === params.deptcode
          ).deptname,
        });
      }
      if (params.xmlb) {
        list.push({
          type: "xmlb",
          value: params.xmlb,
          label: this.getDictList("sq_policy_xmlb").find(
            (cv) => cv.key === params.xmlb
          ).label,
        });
      }
      if (params.level) {
        list.push({
          type: "level",
          value: params.level,
          label: this.getDictList("policy_level")
            .slice(2).concat(this.getDictList("policy_level_qx"))
            .find((cv) => cv.key == params.level).label,
        });
      }
      if (params.cylb) {
        list.push({
          type: "cylb",
          value: params.cylb,
          label: this.cpu_cylbList.find((cv) => cv.key === params.cylb).label,
        });
      }
      if (params.zcfs) {
        list.push({
          type: "zcfs",
          value: params.zcfs,
          label: this.getDictList("sq_policy_zjlx").find(
            (cv) => cv.key === params.zcfs
          )?.label,
        });
      }
      if (params.temp_amount) {
        let item = {
          type: "temp_amount",
          value: params.temp_amount,
          label: "",
        };
        switch (params.temp_amount) {
          case "0":
          case "1":
          case "2":
          case "3":
            item.label = this.searchInfo.amountList.find(
              (cv) => cv.value == params.temp_amount
            ).label;
            break;
          default:
            item.label = `${params.zzxeleft || "--"}万元 ~ ${
              params.zzxeright || "--"
            }万元`;
            break;
        }
        list.push(item);
      }
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    toSearch(){
      this.getFavorablePolicy()
    },
    clear(){
      this.getFavorablePolicy()
    },
    // 惠企政策列表查询
    getFavorablePolicy() {
      let that = this;
      this.dataLoading = true;
      let url =
        "/dev-api/cfgpermitconfig/query/data/find/sq_policy_by_fbjg_cylb_areaid";
      let params = {
        PAGE_CUR: this.pageInfo.page.toString(),
        PAGE_ROWS: this.pageInfo.size.toString(),
        deptcode: this.searchInfo.data.deptcode,
        cylb: this.searchInfo.data.cylb,
        areaid: this.searchInfo.data.level,
        zcfs: this.searchInfo.data.zcfs,
        name:this.keyWords,
      };
      this.$httpApi.post(url, params).then((res) => {
        that.dataLoading = false;
        that.policyDataList = res.data;
        that.pageInfo.total = res.pageInfo.pageCount;
      });
    },
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      for (const k of Object.keys(this.searchInfo.data)) {
        this.searchInfo.data[k] = "";
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );
      this.getFavorablePolicy();
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );
      this.getFavorablePolicy();
    },
    /**
     * @description: 修改搜索类型
     * @param {String} val 值
     */
    changeSearchType(val) {
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );
      this.getFavorablePolicy();
    },
    /**
     * @description 合并表格行列
     * @param {Object} row 行
     * @param {Object} column 列
     * @param {Number} rowIndex 行下标
     * @param {Number} columnIndex 行下标
     */
    mergeTable({ row, column, rowIndex, columnIndex }) {
      // console.log('mergeTable:', row, column, rowIndex, columnIndex)
      if (column.type === "index" || column.property === "name") {
        // 是否与上一行同政策，是 则不占单元格
        if (0 < rowIndex && row.name === this.showList[rowIndex - 1].name) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
        // 是否与下一行同政策，是 则占2行单元格
        let rowNum = 1;
        if (rowIndex < this.showList.length - 1) {
          for (let i = rowIndex + 1; i < this.showList.length; i++, rowNum++) {
            if (row.name !== this.showList[i].name) break;
          }
          return {
            rowspan: rowNum,
            colspan: 1,
          };
        }
      }
    },
    /**
     * @description: 点击前往政策办事指南
     * @param {Object} row 政策信息
     */
    toPolicyGuide(row, column) {
      // console.log('toPolicyGuide:', row, column)
      // 如果点击的是 政策项目 一列，办事指南中的 资助标准 显示所有项
      // 否，则传入qxId，办事指南中的资助标准显示对应的一项
      this.$store.commit("updateCurrentGuideId", row.id);
      let query = {
        id: row.id,
        name: row.name,
      };
      column.property !== "name" && (query.qxId = row.qxid);
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: query,
      });
      window.open(href, "_blank");
    },
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //比对收藏列表,判断是否收藏
    isItemCollect(item) {
      let list = this.collectList;

      for (let i = 0; i < list.length; i++) {
        let collectItem = list[i];
        if (item.id == collectItem.collectionId) {
          // console.log(item.name + ":已收藏");
          return collectItem;
        }
      }
      return null;
    },
    /**
     * @description: 切换页码
     * @param {Number} page 页码
     */
    handleCurrentChange(page) {
      this.pageInfo.page = page.toString();
      this.getFavorablePolicy();
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /*
     * 监听DeclareCeil子组件点击获取详情事件
     */
    clickCell(item) {
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideSuqiu",
          query: {
            name: item.name,
            itemType: item.itemtype,
            isAdded: item.isAdded,
            linkType: true,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "支持方式":
          if (value !== "1") {
            this.$set(this.searchInfo.data, "temp_amount", "");
            this.searchInfo.data.zzxeleft = "";
            this.searchInfo.data.zzxeright = "";
          }
          break;
        case "资金限额":
          if (value == "4") return;
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );
      this.getFavorablePolicy();
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      // let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let url = "/dev-api/sqzdManager/data/find/sq_policy_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
  },
  created() {
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //行政层级,
        "policy_level_qx", //行政层级
      ],
    });
    this.getDeptList();
    this.getFavorablePolicy();
  },
  mounted() {},
  watch: {
    userInfo: {
      // deep: true,
      immediate: true,
      handler: function (newVal) {
        // console.log('userInfo:', newVal)
        this.getCollectList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/jztuisong.png") no-repeat center / cover;
  display: flex;
  align-items: center;
  .bannerTxt {
    // position: absolute;
    // top: -163px;
    // left: 25px;
    color: #fff !important;
    background: transparent;
    .chTxt {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
    }
    .enTxt {
      font-size: 22px;
      font-weight: 500;
      word-spacing: 5px;
      letter-spacing: 2px;
    }
  }
}
::v-deep .filter-item {
  padding: 10px 20px;
}
.banner {
  width: 100%;
  height: 180px;
  background-image: url("~@/assets/deyangImg/program.png");
  background-position-x: center;
  // padding-top: 162px;
  background-size: cover;
  background-repeat: no-repeat;
}
.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 15px;
    }
  }

  .section-header {
    border-bottom: 4px solid #000;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 24px;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 46px;
    line-height: 46px;
  }

  .rank-section {
    padding-top: 10px;
    font-size: 13px;
  }

  .find-tabs {
  }
}
.find-list-container {
  .el-input {
    input {
      background-color: #ffffff;
      border-radius: 0px !important;
    }
  }

  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 360px;
    display: flex;

    .el-button {
      border: 0px;
      border-radius: 0px;
      width: 90px;

      .el-icon-bottom {
        margin-left: 15px;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }
}
::v-deep {
  .filterItem-content .content-item.content-item--active {
    background-color: #22b7fd;
  }
  .filterItem-content .content-item:hover {
    color: #22b7fd;
  }
}
::v-deep .searchItem-amount {
  border-top: none !important;

  .filterItem-label {
    opacity: 0;
  }
  .filterItem-content {
    padding-left: 73px;

    .content-item--active {
      color: #22b7fd !important;
      background: none !important;
    }
  }

  .searchItem-amount-custom {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #999999;

    .form-input {
      width: 80px;
      margin-right: 5px;
    }
  }
}
.search-params-clean {
  margin-right: 5px;
  color: $col-red;
}

.find-list-items-container {
  .item {
    margin-bottom: 10px;
    box-sizing: border-box;
    position: relative;
  }
}
::v-deep .table-policy {
  .table-policy-header {
    th {
      color: #666666;
      background-color: #dbdada;
    }
  }
  .policy-item {
    .policyItem-hylb,
    .policyItem-qyzz,
    .policyItem-ryxxyq {
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .policyItem-zzxe {
      color: $col-red;
    }
    .policyItem-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .tj-icon {
        font-size: 20px;
        color: #999999;

        &:hover {
          color: #666666;
        }
      }
    }

    &:hover {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.pagination {
  margin: 30px auto;
  text-align: center;
}
.filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    // color: $col-theme;

    .el-tag__close {
      // color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    // background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: #22b7fd;
  }
  .filterItem-right span {
    color: #22b7fd;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: #22b7fd !important;
  }
  .el-checkbox-button__inner:hover {
    color: #22b7fd;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
  .filterItem-content .content-item {
    font-size: 1.125rem;
  }
  .filterItem-label {
    font-size: 1rem;
  }
  .infoItem-label {
    font-size: 1rem;
  }
  .infoItem-value {
    font-size: 1rem;
  }
}
.input-search-bar {
  height: 55px;
  width: 50%;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  margin-top: 10px;
  /* 输入框头部 */
  .input-search-pre {
    width: 120px !important;
    color: #404040;
    font-size: 16px;
    background: $color-fff;
    .el-input__inner {
      text-align: center !important;
      border: none !important;
      border-radius: 0 !important;
      color: #333;
      background-color: #ffffff !important;
    }
  }

  /* 输入框内容 */
  .input-search {
    flex: 1;
    .el-input__inner {
      border: none !important;
      border-radius: 0 !important;
      background-color: #ffffff !important;
      font-size: 18px;
    }
    input::-webkit-input-placeholder {
      color: #999999;
    }
  }
  /* 输入框按钮 */
  .input-search-btn {
    background: #03b1ff;
    width: 100px;
    height: 40px;
    line-height: 14px;
    border-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    // border: 2px solid #b6a99c !important;
    text-align: center;
    font-size: $font-size18;
    color: $color-fff;
    cursor: pointer;
    .el-button {
      font-weight: bold !important;
    }
  }
}
::v-deep .el-input__suffix {
  top:-5px !important;
}
</style>
