<template>
  <!-- @click="clickCell" -->
  <div class="declareCell">
    <!-- 扶持方式 -->
    <div v-if="item.zcfs" class="item-fcfs">
      {{ getDictLabel("sq_policy_zjlx", item.zcfs) }}
    </div>
    <div class="item-inner">
      <div class="inner-left">
        <!-- 政策名称 -->
        <div class="item-title">
          <h2>
            {{ item.name || item.NAME || ""
            }}<span class="msjx" v-if="item.type == 0">免申即享</span>
          </h2>
        </div>
        <!-- 政策信息 -->
        <div class="item-info">
          <!-- <div class="info-item" v-for="(info, index) in infoList" :key="index">
            <label class="infoItem-label">{{ info.label }}：</label>
            <div class="infoItem-value">
              {{ info.formatter ? info.formatter(item) : item[info.valueKey] }}
            </div>
          </div> -->
          <div class="info-item">
            <label class="infoItem-label">发布部门：</label>
            <div class="infoItem-value">
              {{ item.dept_name }}
            </div>
          </div>
          <div class="info-item">
            <label class="infoItem-label">发布时间：</label>
            <div class="infoItem-value">
              {{ item.createtime.split(" ")[0] }}
            </div>
          </div>
        </div>
      </div>
      <div class="inner-right">
        <div>
          <div
            class="follow-img"
            :class="isFollow ? 'follow-img-on' : ''"
            @click.stop="clickIsFollow"
          ></div>
          <!-- <div>
            <el-button
              type="primary"
              size="medium"
              @click.stop="applyGuide(item)"
              >申报指南
            </el-button>
            <el-button type="danger" size="medium" @click.stop="policy(item)"
              >政策解读</el-button
            >
          </div> -->
          <template v-if="item.isApply && item.isApply == '1'">
            <el-button
              v-if="cpu_limitTime == '已过期'"
              disabled
              size="medium"
              @click.stop="apply"
              >一键申报
            </el-button>
            <el-button v-else class="item-go" size="medium" @click.stop="apply"
              >一键申报
            </el-button>
          </template>
          <el-button class="item-go" size="medium" @click="clickCell(item)"
            >办事指南
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="60%"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="dialogContent">
        <div v-if="dialogFlag == 0">
          <el-table :data="applyProjectData" style="width: 100%" height="460px">
            <el-table-column
              prop="item_base_code"
              label="项目编码"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="项目名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="dept_name"
              label="发布部门"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="期限" align="center" width="200">
              <template slot-scope="{ row }">
                {{ row.start_time }} ~ {{ row.endtime }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <!-- <el-button type="primary" size="small">申报指南</el-button>
              <el-button type="primary" size="small">一键申报</el-button> -->
              <template slot-scope="{ row }">
                <template v-if="row.is_apply && row.is_apply == '1'">
                  <el-button
                    v-if="cpu_limitTime(row) == '已过期'"
                    disabled
                    size="small"
                    @click.stop="apply"
                    >一键申报
                  </el-button>
                  <el-button
                    v-else
                    class="item-go"
                    size="small"
                    @click.stop="apply"
                    >一键申报
                  </el-button>
                </template>

                <el-button class="item-go" size="small" @click="clickCell(row)"
                  >办事指南
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageInfo.pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pageInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
            >
            </el-pagination>
          </div>
        </div>
        <div v-if="dialogFlag == 1">
          <el-scrollbar style="width: 95%">
            <el-form
              ref="sysPolicyExplain"
              :model="sysPolicyExplain"
              :rules="rules"
              label-width="120px"
            >
              <el-form-item label="标题" prop="name">
                <el-input
                  disabled
                  v-model="sysPolicyExplain.name"
                  placeholder="请输入标题"
                />
              </el-form-item>
              <el-form-item label="所属区划" prop="areaid">
                <el-cascader
                  disabled
                  :options="optionsArea"
                  filterable
                  clearable
                  v-model="sysPolicyExplain.areaid"
                  style="width: 100%"
                  :show-all-levels="false"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="颁布部门" prop="deptid">
                <el-cascader
                  disabled
                  :options="optionsDept"
                  filterable
                  clearable
                  v-model="sysPolicyExplain.deptid"
                  style="width: 100%"
                  :show-all-levels="false"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input
                  disabled
                  v-model="sysPolicyExplain.sort"
                  placeholder="请输入排序"
                />
              </el-form-item>
              <el-tabs v-model="editableTabsValue" type="card">
                <el-tab-pane
                  v-for="item in sysPolicyExplain.contentReceive"
                  :key="item.name"
                  :label="item.title"
                  :name="item.name"
                >
                  <el-form-item label="解读内容">
                    <editor disabled v-model="item.content" :min-height="192" />
                  </el-form-item>
                  <!-- <el-form-item label="显示状态">
                    <el-radio-group v-model="item.status" disabled>
                      <el-radio :label="'0'">显示</el-radio>
                      <el-radio :label="'1'">隐藏</el-radio>
                    </el-radio-group>
                  </el-form-item> -->
                </el-tab-pane>
              </el-tabs>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/dateHandler.js";
import variableStyle from "@/styles/variable.scss";
import Editor from "@/components/Editor";
import { optionsArea, optionsDept } from "@/views/findList/areaDept.js";
const NOW = new Date();

export default {
  name: "declareCell",
  components: { Editor },
  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    bgColor: {
      type: String,
      default: function () {
        return "#ffffff";
      },
    },
    isShowText: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    collectItem: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      dialogFlag: 0,
      applyProjectData: [],
      pageInfo: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      optionsDept: [],
      optionsArea: [],
      sysPolicyExplain: {
        sort: 1,
        contentReceive: [
          {
            title: "图片",
            type: "1",
            name: "1",
            status: "0",
            content: "",
          },
          {
            title: "图文",
            type: "2",
            name: "2",
            status: "0",
            content: "",
          },
          {
            title: "文字",
            type: "3",
            name: "3",
            status: "0",
            content: "",
          },
        ],
      },
      editableTabsValue: "3",
      rules: {},
      collectItemId: this.collectItem == null ? "" : this.collectItem.id,
      isFollow: this.collectItem != null,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictLabel: "dictionary/getDictLabel",
      storeguideId: "getCurrentGuideId",
    }),
    /**
     * @description: 剩余天数的颜色
     */
    cpu_limitTimeColor() {
      switch (this.cpu_limitTime) {
        case "未开始":
          return "";
        case "已过期":
          return variableStyle["color-red"];
        default:
          return variableStyle["color-yellow"];
      }
    },
  },
  methods: {
    cpu_limitTime(item) {
      // 区分几种情况
      //     未开始/剩余天数/已过期
      let startTime = new Date(item.start_time || item.starttime);
      if (NOW < startTime) return "未开始";

      let endTime = new Date(item.endtime);
      if (endTime < NOW) return "已过期";

      let time = endTime.getTime() - NOW.getTime();
      return `剩${parseInt(time / (24 * 60 * 60 * 1000))}天`;
    },
    // 一键申报
    apply() {
      // 判断是否登录
      if (this.userInfo) {
        this.$router.push({
          name: "policyDeclaration",
          query: {
            policyId: this.storeguideId,
          },
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    clickCell(row) {
      this.$emit("clickCell", row);
    },
    clickIsFollow() {
      console.log("clickIsFollow", this.isFollow);
      let isFLW = this.item;
      isFLW.isShowText = !isFLW.isShowText;
      if (this.$store.getters.getUserInfo) {
        if (this.isFollow) {
          this.cancelCollect();
        } else {
          this.collect();
        }
      } else {
        this.$emit("clickIsFollow", this.item);
      }
    },
    //收藏
    collect() {
      let id = this.item.id || this.item.ID;
      console.log("收藏参数设置" + this.item);
      let identify = this.$store.getters.getUserInfo.shxydm;

      let name = this.item.name || this.item.NAME;
      let itemType = this.item.itemtype || this.item.ITEMTYPE;
      let collectedType = 4;
      if (itemType == "1") {
        collectedType = 4;
      } else if (itemType == "2") {
        collectedType = 5;
      } else if (itemType == "3") {
        collectedType = 6;
      }
      let param = {
        collectionName: name,
        collectionId: id,
        identify: identify,
        type: collectedType,
      };
      console.log("收藏参数：", param);
      this.$httpApi
        .post("/dev-api/collection/install", param)
        .then((res) => {
          console.log("添加收藏" + JSON.stringify(res));
          if (res.code == 200 && res.data) {
            this.collectItemId = res.id;
            this.isFollow = true;
          }
        })
        .catch((err) => {});
    },
    //取消收藏
    cancelCollect() {
      let collectItemId = this.collectItemId;
      console.log("取消收藏参数：", collectItemId);
      this.$httpApi
        .post("/dev-api/collection/delete/" + collectItemId, {})
        .then((res) => {
          console.log("取消收藏" + JSON.stringify(res));
          if (res.code == 200) {
            this.collectItemId = "";
            this.isFollow = false;
          }
        })
        .catch((err) => {});
    },
    // 获取可申报项目
    getProjects(policyid) {
      let url =
        "/dev-api/cfgpermitconfig/query/data/find/query_item_by_policyid";
      let params = {
        PAGE_CUR: this.pageInfo.pageNum.toString(),
        PAGE_ROWS: this.pageInfo.pageSize.toString(),
        policyid: policyid,
      };
      this.$httpApi.post(url, params).then((res) => {
        this.applyProjectData = res.data;
        this.pageInfo.total = res.pageInfo.pageCount;
      });
    },
    // 获取政策解读
    getPolicyExplain(policyid) {
      let url = "/dev-api/basicinfo/baseitem/queryExplainByZcid";
      let params = {
        // PAGE_CUR: this.pageInfo.pageNum.toString(),
        // PAGE_ROWS: this.pageInfo.pageSize.toString(),
        policyid: policyid,
      };
      this.$httpApi.post(url, params).then((response) => {
        if (response.code == 200) {
          this.sysPolicyExplain = response.data;
          let arr = this.sysPolicyExplain.deptid.split(",");
          this.sysPolicyExplain.deptid = arr;
          if (
            this.sysPolicyExplain.contentReceive &&
            this.sysPolicyExplain.contentReceive[0]
          ) {
            this.editableTabsValue =
              this.sysPolicyExplain.contentReceive[0].name;
          }
        }
      });
    },
    async applyGuide(item) {
      let id = item.id;
      await this.getProjects(id);
      this.dialogVisible = true;
      this.dialogTitle = "可申报项目";
      this.dialogFlag = 0;
    },
    async policy(item) {
      let id = item.id;
      await this.getPolicyExplain(id);
      this.dialogVisible = true;
      this.dialogTitle = "政策解读";
      this.dialogFlag = 1;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getProjects();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getProjects();
    },
    // 获取部门
    getTree() {
      this.optionsDept = optionsDept;
    },
    // 获取区划
    getArea() {
      this.optionsArea = optionsArea;
    },
    init() {
      this.getTree();
      this.getArea();
    },
  },
  created() {
    this.guideId = this.item.id || this.storeguideId;
    this.$store.dispatch("dictionary/commonSetDic", {
      types: ["sq_policy_zjlx"],
    });
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.msjx {
  display: inline-block;
  padding: 3px 8px;
  border: 1px solid #34c56e;
  border-radius: 2px;
  color: #34c56e;
  font-size: 12px;
  font-weight: normal;
  vertical-align: middle;
  margin-left: 5px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #35c1ff;
}
// table的滚动条
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; /*滚动条宽度*/
  height: 10px; /*滚动条高度*/
}
/*定义滚动条轨道 内阴影+圆角*/
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px; /*滚动条的背景区域的圆角*/
  background-color: #ccc; /*滚动条的背景颜色*/
}
/*定义滑块 内阴影+圆角*/
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: #999; /*滚动条的背景颜色*/
}
.dialogContent {
  width: 100%;
  min-height: 500px;
}
.declareCell {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 10px 20px 60px;
  border: 1px solid #eeeeee;
  background-color: #f9f9f9;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  overflow: hidden;

  .item-fcfs {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: $col-yellow;
    // -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    // -moz-transform: translate(-50%, -50%) rotate(-45deg);
    // -o-transform: translate(-50%, -50%) rotate(-45deg);
    // -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(calc(-50% + 30px), calc(-50% + 30px)) rotate(-45deg);
  }

  .item-inner {
    display: -webkit-flex;
    display: flex;

    .inner-left {
      flex: 1;
    }

    .inner-right {
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 230px;
      min-height: 80px;
      padding-left: 10px;
      border-left: 2px solid #ffffff;
      text-align: center;
      // position: relative;

      .right-line {
        color: #cccccc;
      }

      .marg-rl-2 {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .item-title {
    margin-bottom: 10px;
    font-family: Microsoft YaHei;
    color: #333333;
  }

  .item-info {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
    color: #666666;

    .info-item {
      display: flex;

      .infoItem-value {
        flex: 1;
      }
    }
  }

  .item-capital {
    color: $col-red;
  }

  .follow-img,
  .item-go {
    vertical-align: middle;
  }

  .follow-img {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("../assets/images/favorites_off.png") center / 100% 100%
      no-repeat;
    position: absolute;
    left: 24px;
    top: 30px;
  }

  .follow-img-on {
    background: url("../assets/images/favorites_on.png") center / 100% 100%
      no-repeat;
  }
}

.declareCell:hover {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.item-go {
  margin-left: 10px;
  border-color: $col-theme;
  color: $col-theme;

  &:hover {
    color: #ffffff;
    background-color: $col-theme;
  }
}
::v-deep .el-button[disabled="disabled"] {
  background-color: #fff !important;
}
</style>
